import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Icon } from "@cochlear-design-system/foundation/dist/esm/Elements/Icon/Icon";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import GenericButtonBar from "../GenericButtonBar/GenericButtonBar";
import GenericImage from "../GenericImage/GenericImage";
import GenericRichText from "../GenericRichText/GenericRichText";
import GenericVideo from "../GenericVideo/GenericVideo";
import useIsMobile from "../../../../utils/useIsMobile";

const MOBILE_CARD_MARGIN = 16;
const DESKTOP_CARD_MARGIN = 24;

const GenericCardGroup = ({ fields }) => {
  const [activeIndex, setActiveIndex] = useState(
    (fields.activeItem?.id &&
      fields.items.findIndex(({ id }) => id === fields.activeItem.id)) ??
      0,
  );

  const [containerStyle, setContainerStyle] = useState({
    // temporary width before setting after render
    // assume maximum possible scrollbar width is 20px
    marginLeft: "calc(-50vw + 10px)",
    marginRight: "calc(-50vw + 10px)",
    width: "calc(100vw - 20px)",
  });
  const [cardWidth, setCardWidth] = useState(null);
  const [numCardsOnScreen, setNumCardsOnScreen] = useState(1);

  const [containerEl, setContainerEl] = useState(null);

  const controlsWrapperRef = useRef(null);

  // workaround to get the start edge of a col-12 container without replicating CSS in javascript
  const [col12StartEdge, setCol12StartEdge] = useState(0);

  const isMobile = useIsMobile();

  const previousWidth = useRef(null);

  useEffect(() => {
    previousWidth.current = document.documentElement.clientWidth;

    setContainerStyle({
      marginLeft: document.documentElement.clientWidth * -0.5,
      marginRight: document.documentElement.clientWidth * -0.5,
      width: document.documentElement.clientWidth,
    });

    const resizeObserver = new ResizeObserver((entries) => {
      // only update when width of window changes, not height
      // otherwise change in content size changes the window height for an infinite loop
      const newWidth = entries[0].borderBoxSize?.[0].inlineSize;

      if (newWidth !== previousWidth.current) {
        previousWidth.current = newWidth;

        // force 100% width not including scrollbar width, can't use vw
        setContainerStyle({
          marginLeft: document.documentElement.clientWidth * -0.5,
          marginRight: document.documentElement.clientWidth * -0.5,
          width: document.documentElement.clientWidth,
        });
      }

      if (controlsWrapperRef.current) {
        setCol12StartEdge(
          controlsWrapperRef.current.getBoundingClientRect().left,
        );
      }
    });

    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.unobserve(document.documentElement);
  }, []);

  // get card width for scrolling
  useEffect(() => {
    if (!containerEl) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setCardWidth(containerEl.children.item(0).getBoundingClientRect().width);
    });

    resizeObserver.observe(document.documentElement);
    resizeObserver.observe(containerEl);

    return () => {
      resizeObserver.unobserve(document.documentElement);
      resizeObserver.unobserve(containerEl);
    };
  }, [containerEl]);

  // calculate number of cards on screen
  useEffect(() => {
    if (!cardWidth) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setNumCardsOnScreen(
        Math.floor(
          ((window.innerWidth +
            controlsWrapperRef.current.getBoundingClientRect().width) /
            2 +
            (isMobile ? MOBILE_CARD_MARGIN : DESKTOP_CARD_MARGIN)) /
            (cardWidth + (isMobile ? MOBILE_CARD_MARGIN : DESKTOP_CARD_MARGIN)),
        ),
      );
    });

    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.unobserve(document.documentElement);
  }, [cardWidth, isMobile]);

  const handlePrev = () => {
    setActiveIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setActiveIndex((prev) =>
      Math.min(prev + 1, fields.items.length - numCardsOnScreen),
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const isRtl =
        !!containerEl &&
        window.getComputedStyle(containerEl).direction === "rtl";

      isRtl ? handlePrev() : handleNext();
    },
    onSwipedRight: () => {
      const isRtl =
        !!containerEl &&
        window.getComputedStyle(containerEl).direction === "rtl";

      isRtl ? handleNext() : handlePrev();
    },
  });

  const translateX = (() => {
    if (typeof window === "undefined" || !cardWidth) {
      return 0;
    }

    const containerStartEdge = fields.fullWidthCard.value
      ? // centred
        (document.documentElement.clientWidth - cardWidth) / 2
      : // start edge of a col-12 container, plus offset for mobile
        col12StartEdge + (isMobile ? 8 : 0);

    const isRtl =
      containerEl && window.getComputedStyle(containerEl).direction === "rtl";

    return (
      containerStartEdge * (isRtl ? -1 : 1) +
      activeIndex *
        (cardWidth + (isMobile ? MOBILE_CARD_MARGIN : DESKTOP_CARD_MARGIN)) *
        (isRtl ? 1 : -1)
    );
  })();

  // Play slider while page scrolling
  const [scrollSyncEnabled, setScrollSyncEnabled] = useState(false);
  const [counter, setCounter] = useState(0);
  const elRef = useRef(null);
  const cardCount = fields?.items?.length || 0;

  useEffect(() => {
    elRef.current = document.getElementsByClassName(
      "fullscreen-section-card-group",
    )[0];

    if (!elRef.current) {
      return;
    }

    setScrollSyncEnabled(true);

    elRef.current.style.height = cardCount * 2 * 1000 + "px";

    const elHeight = cardCount * 2 * 1000;
    const windowHeight = window.innerHeight;
    let throttle = 0;
    let scrollUp = true;
    let position = 0;
    let scrolled = 0;
    let stTracker = 0;
    let stTracker2 = 0;

    const handleScroll = (e) => {
      position = elRef.current.getBoundingClientRect().top;

      if (position > windowHeight) {
        return false;
      }

      if (throttle === 0) {
        // Scroll direction
        throttle = new Date().valueOf();
        scrolled = window.scrollY;

        return false;
      } else {
        if (new Date().valueOf() - throttle > 60) {
          clearTimeout(stTracker);
          clearTimeout(stTracker2);

          // Scroll direction
          if (window.scrollY - scrolled > 0) {
            scrollUp = true;
          } else {
            scrollUp = false;
          }

          throttle = new Date().valueOf();
          scrolled = window.scrollY;
        } else {
          return false;
        }
      }

      if (scrollUp) {
        if (position <= 0) {
          // Out of viewport
          if (position < elHeight * -1) {
            elRef.current.classList.remove("virtual-scrolling");
            return false;
          }

          // Entered viewport
          if (!elRef.current.classList.contains("virtual-scrolling")) {
            elRef.current.classList.add("virtual-scrolling");
          }

          // Active index based on swiped distance
          let activeIndex = Math.floor(
            Math.abs((position / elHeight) * cardCount),
          );
          activeIndex = activeIndex >= cardCount ? cardCount - 1 : activeIndex;

          if (activeIndex > counter) {
            setCounter(activeIndex);
            setActiveIndex(activeIndex);

            clearTimeout(stTracker);
            clearTimeout(stTracker2);
          } else {
            if (position < -400 && position > (elHeight - windowHeight) * -1) {
              stTracker = setTimeout(() => {
                scrolled = window.scrollY;
              }, 100);

              stTracker2 = setTimeout(() => {
                if (window.scrollY - scrolled <= 1) {
                  let activeIndexBasedOnSwipe = counter + 1;
                  activeIndexBasedOnSwipe =
                    activeIndexBasedOnSwipe >= cardCount - 1
                      ? cardCount - 1
                      : activeIndexBasedOnSwipe;

                  scrolled = window.scrollY;
                  setCounter(activeIndexBasedOnSwipe);
                  setActiveIndex(activeIndexBasedOnSwipe);

                  if (activeIndexBasedOnSwipe >= cardCount - 1) {
                    window.scrollTo({
                      left: 0,
                      top: window.scrollY + position + elHeight - windowHeight,
                      behavior: "instant",
                    });
                  }
                }
              }, 200);
            }
          }
        }
      }

      // Swiping down
      if (!scrollUp) {
        if (position < 0) {
          if (position < elHeight * -1) {
            return false;
          } else if (position + elHeight < windowHeight) {
            // entering
            setCounter(cardCount - 1);
            setActiveIndex(cardCount - 1);
            return false;
          } else {
            if (!elRef.current.classList.contains("virtual-scrolling")) {
              elRef.current.classList.add("virtual-scrolling");
            }
          }

          let activeIndex2 = Math.floor(
            Math.abs(position - (windowHeight / elHeight) * cardCount),
          );
          activeIndex2 =
            activeIndex2 >= cardCount ? cardCount - 1 : activeIndex2;

          if (activeIndex2 < counter) {
            setCounter(activeIndex2);
            setActiveIndex(activeIndex2);

            clearTimeout(stTracker);
            clearTimeout(stTracker2);
          } else {
            if (position > (elHeight - windowHeight - 400) * -1) {
              stTracker = setTimeout(() => {
                scrolled = window.scrollY;
              }, 100);

              stTracker2 = setTimeout(() => {
                if (window.scrollY - scrolled <= 1) {
                  let activeIndexBasedOnSwipe = counter - 1;
                  activeIndexBasedOnSwipe =
                    activeIndexBasedOnSwipe <= 0 ? 0 : activeIndexBasedOnSwipe;

                  scrolled = window.scrollY;
                  setCounter(activeIndexBasedOnSwipe);
                  setActiveIndex(activeIndexBasedOnSwipe);

                  if (activeIndexBasedOnSwipe <= 0) {
                    window.scrollTo({
                      left: 0,
                      top: window.scrollY - position * -1,
                      behavior: "instant",
                    });
                  }
                }
              }, 200);
            }
          }
        } else {
          setCounter(0);
          setActiveIndex(0);
          elRef.current.classList.remove("virtual-scrolling");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [counter]);

  // move carousel if another card can fit on screen after resize
  // exclude for scroll sync
  useEffect(() => {
    if (
      activeIndex > fields.items.length - numCardsOnScreen &&
      !scrollSyncEnabled
    ) {
      setActiveIndex(Math.max(fields.items.length - numCardsOnScreen, 0));
    }
  }, [activeIndex, numCardsOnScreen, scrollSyncEnabled]);

  return (
    <div
      className={`card-group-container ${
        scrollSyncEnabled ? "scroll-sync" : ""
      }`}
      style={containerStyle}
    >
      <div className="controls-container">
        <div className="controls-wrapper" ref={controlsWrapperRef}>
          {fields.showControls.value &&
          fields.items.length > numCardsOnScreen ? (
            <>
              <span
                className={`control-button${
                  activeIndex === 0 ? " disabled" : ""
                }`}
                onClick={handlePrev}
              >
                <Icon
                  color={activeIndex === 0 ? "disabled" : "interactive"}
                  size="sm"
                  type="chevron-left"
                />
              </span>
              <span
                className={`control-button${
                  activeIndex >= fields.items.length - numCardsOnScreen
                    ? " disabled"
                    : ""
                }`}
                onClick={handleNext}
              >
                <Icon
                  color={
                    activeIndex >= fields.items.length - numCardsOnScreen
                      ? "disabled"
                      : "interactive"
                  }
                  size="sm"
                  type="chevron-right"
                />
              </span>
            </>
          ) : null}
        </div>
      </div>

      <div className="card-carousel-container" {...handlers}>
        <div
          className="card-container"
          ref={setContainerEl}
          style={{ transform: `translateX(${translateX}px)` }}
        >
          {fields.items.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`card-group-item${
                  fields.fullWidthCard.value ? " full-width" : ""
                }${
                  fields.itemStyle.name === "style 2" ? " rounded-corners" : ""
                }${
                  fields.itemStyle.name === "style 2" &&
                  fields.cardBackgroundColor?.name
                    ? ` bg-${fields.cardBackgroundColor.name}`
                    : ""
                }${
                  idx >= activeIndex && idx < activeIndex + numCardsOnScreen
                    ? " active"
                    : ""
                }${fields.bottomAlignCTA?.value ? " bottom-align-button" : ""}`}
              >
                <div className="card-group-item-media">
                  {"image" in item.fields ? (
                    <GenericImage
                      fields={{
                        curveCorner:
                          fields.itemStyle.name === "style 1"
                            ? { name: "bottom right" }
                            : null,
                        fillHeight: { value: true },
                        image: item.fields.image,
                      }}
                    />
                  ) : "youtubeVideoID" in item.fields ? (
                    <GenericVideo
                      fields={{
                        autoPlay: item.fields.autoPlay,
                        fillHeight: { value: true },
                        hideControls: item.fields.hideControls,
                        overlayImage: item.fields.overlayImage,
                        youtubeVideoID: item.fields.youtubeVideoID,
                      }}
                    />
                  ) : null}
                </div>
                <div className="card-group-item-text">
                  <GenericRichText fields={{ text: item.fields.text }} />
                  <GenericButtonBar
                    fields={{
                      items: [
                        item.fields.cta1.value.href &&
                        item.fields.cta1.value.text
                          ? {
                              fields: {
                                link: item.fields.cta1,
                                style: { name: "brand-primary" },
                              },
                            }
                          : null,
                        item.fields.cta2.value.href &&
                        item.fields.cta2.value.text
                          ? {
                              fields: {
                                link: item.fields.cta2,
                                style: { name: "secondary" },
                              },
                            }
                          : null,
                      ].filter((val) => val),
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {fields.showProgress.value ? (
        <div className="progress-indicators-container">
          {fields.items.length > numCardsOnScreen
            ? Array(fields.items.length - numCardsOnScreen + 1)
                .fill()
                .map((_, idx) => (
                  <span
                    className={`${
                      idx === activeIndex && numCardsOnScreen > 1
                        ? "progress-indicator-long-dot"
                        : "progress-indicator-dot"
                    }${
                      idx === activeIndex && numCardsOnScreen === 1
                        ? " active"
                        : ""
                    }${idx <= activeIndex ? " visited" : ""}`}
                    key={
                      idx === activeIndex && numCardsOnScreen > 1
                        ? "long-dot"
                        : idx
                    }
                    // even though idx is behind the actual index,
                    // clicking on a dot will scroll the end of the long dot to that index
                    onClick={() => setActiveIndex(idx)}
                  />
                ))
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default withSitecoreContext()(GenericCardGroup);
